var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition-group",
    {
      staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4 pr-4",
      attrs: { name: "zoom-fade", tag: "div" },
    },
    _vm._l(_vm.unfinishedStreams, function (stream) {
      return _c(
        "div",
        {
          key: stream.user.id,
          class: { "col-span-2": _vm.expendedUserId === stream.user.id },
        },
        [
          _c("StreamPlayer", {
            key: stream.user.finished_at,
            ref: `stream-${stream.user.id}`,
            refInFor: true,
            attrs: {
              roomId: _vm.roomId,
              user: stream.user,
              answerId: stream.questionnaire_answer_id,
              finished: stream.user.finished_at,
              "proctoring-service": _vm.proctoringService,
              "media-stream": _vm.userMediaStream,
              config: _vm.config,
            },
            on: { expanded: _vm.expand, supervise: _vm.startSupervision },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }